import { stringifyQueryString } from '@shein/common-function'

const host = gbCommonInfo?.host

const getAutoCouponCodes = (infos) => {
  if (!Array.isArray(infos) || !infos.length) return ''
  return infos.map((v) => (v.coupon_code || '').toUpperCase()).join(',')
}
const removeNullAttr = (obj) => {
  return Object.fromEntries(Object.entries(obj).filter(([, v]) => !!v))
}

/**
 * 计算跳转下单页的url
 * @param {prime_product_code} 似乎已不用 
 * @param {coupon_infos} 优惠券数组
 */
export const getToCheckoutUrl = (props) => {
  let resUrl = typeof host === 'string' ? host : ''
  let defaultUrl = '/checkout'
  try {
    const { prime_product_code, coupon_infos = [] } = props ?? {}
    const queryString = stringifyQueryString({
      queryObj: removeNullAttr({
        prime_product_code,
        auto_coupon: getAutoCouponCodes(coupon_infos)
      })
    })
    if (queryString) {
      resUrl += `${defaultUrl}?${queryString}`
    } else {
      resUrl += defaultUrl
    }
  } catch (e) {
    console.error('getToCheckoutUrl Error', e)
    resUrl += defaultUrl
  } finally {
    return resUrl
  }
}
