// wiki.pageId=481264374
class searchWordsGlobalAnalysis {
  #KEY = 'searchWordsGlobalAnalysis'
  TYPE_DESC_MAP = {
    2: 'EditSearch',
    3: 'DefaultSearch',
    4: 'SuggestionSearch',
    5: 'RecentSearch',
    6: 'HotSearch',
    7: 'SuggestionSearchCategory',
    8: 'SuggestionSearchFeedback',
    9: 'HotSearchPic',
    10: 'ListFlow',
    18: 'SuggestionAttri',
    19: 'CorrectOriginalWord',
    20: 'AdsSearch'
  }
  constructor() {
    this.refreshRedir()
  }
  // 跟踪ABC重定向
  refreshRedir() {
    const data = this.get()
    if (data.d || !location.search.includes('search_redir=1')) return
    data.d = 1
    sessionStorage.setItem(this.#KEY, JSON.stringify(data))
  }
  get() {
    let data = sessionStorage.getItem(this.#KEY)
    try {
      data = JSON.parse(data) || {}
    } catch (error) {
      data = {}
    }
    return data
  }
  set(obj) {
    if (Object.prototype.toString.call(obj) === '[object Object]') {
      const originPageName = typeof getSaPageInfo !== 'undefined' && getSaPageInfo.page_name || typeof SaPageInfo !== 'undefined' && SaPageInfo.page_name || ''
      let parent_page = originPageName
        .split('_')
        .map(_ => {
          if (!_) return ''
          return _[0].toLocaleUpperCase() + _.slice(1)
        })
        .join('')
      if (parent_page) {
        // 搜索页来源额外增加描述
        if (originPageName === 'page_search') {
          let searchData = window.gbRawData
          searchData = searchData?.searchKeywords || searchData?.results?.searchKeywords
          const searchCount = searchData?.origin_total || searchData?.suggest_total
          parent_page += (searchCount ? 'Result' : 'NoResult')
        }
        obj.parent_page = parent_page
        obj.entrancetype = (window.SaPageInfo || getSaPageInfo)?.page_param?.entrancetype || '-'
      }
      sessionStorage.setItem(this.#KEY, JSON.stringify(obj))
    }
  }
  getPageFrom() {
    const { result_type, result_word, d, parent_page } = this.get()
    return `s1\`${this.TYPE_DESC_MAP[result_type] || ''}\`${result_word || ''}\`_fb\`d${d || 0}\`${parent_page || 'PageOthers'}`
  }
}

export default typeof window !== 'undefined'
  ? new searchWordsGlobalAnalysis()
  : {}
