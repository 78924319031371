import { HtmlCacheClass } from 'public/src/js/utils/htmlCacheClass'
import { getToCheckoutUrl } from 'public/src/pages/cart_new/utils/getToCheckoutUrl'
import { windowCacheDeleteCb } from '@shein/common-function'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
const isEnable = typeof navigator !== 'undefined' && 'serviceWorker' in navigator && typeof fetch !== 'undefined' && typeof caches !== 'undefined' && location.pathname.indexOf('/user/auth/login') == -1

const getAbt = async () => {
  const { PcCartPrefetch } = await getUserAbtData()
  return PcCartPrefetch?.param?.cart_prefetch_switch === 'on'
}
export function prefetchCart() {
  if (!isEnable) {
    windowCacheDeleteCb({ key: 'cartDynamicData' })
    return
  }

  getAbt().then( enable => {
    if(enable) {
      windowCacheDeleteCb({ key: 'cartDynamicData', cb: () => fetch(location.origin + '/cart', { headers: { 'prefetch': '1' } }) })
    }
  })
}

const HtmlCacheClassInstance = HtmlCacheClass.getInstance()
export const CHECKOUT_PREFETCH_TYPE = {
  LOOPFN_EFFECTS: '1',
  LOOPFN: '2',
  RESET: '3'
}
const handleCompareData = (cartsData) => Array.isArray(cartsData)
  ? cartsData.map(item => ({
    id: item?.id,
    quantity: item?.quantity,
    is_checked: item?.is_checked
  }))
  : cartsData

export const htmlCacheClassFn = async (props) => {
  const { 
    type,
    cartsData,
    path = getToCheckoutUrl(),
    conditions
  } = props ?? {}
  // 分开if，条件比较明朗
  if (typeof window !== 'undefined') {
    if (type === CHECKOUT_PREFETCH_TYPE.RESET) {
      HtmlCacheClassInstance.resetFn()
      return
    }
    if ((Array.isArray(conditions) && conditions?.length > 0 && conditions.every(item => item)) || !conditions) {
      const effectData = handleCompareData(cartsData)
      const prefetchFlag = Array.isArray(cartsData) && cartsData?.length > 0 && cartsData.some(item => item?.is_checked === 1)
      const loopSource = { source: path, carts: cartsData, prefetchFlag }
      switch(type) {
        case CHECKOUT_PREFETCH_TYPE.LOOPFN_EFFECTS:
          HtmlCacheClassInstance.loopFnEffects(() => HtmlCacheClassInstance.loopFn(loopSource), [effectData]); break
        case CHECKOUT_PREFETCH_TYPE.LOOPFN:
          HtmlCacheClassInstance.loopFn(loopSource); break
      }
    }
  }
}
