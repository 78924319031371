import { getLocalStorage } from '@shein/common-function'
import { cartTagTip } from '@shein-aidc/bs-sdk-cart-tag-tip'
import { prefetchCart } from 'public/src/pages/common/cart_module/utils'
import { prefetchResources } from 'public/src/pages/common/cart_module_mini/utils'
import { web_modify_check_click, web_modify_check_response, web_cart_entry_click } from 'public/src/pages/cart_new/utils/metricTagsConfig.js'

const getCartInfo = (cartRes) => {
  const mallCartInfo = cartRes?.mallCartInfo || {}
  let carts = []
  let mallCarts = mallCartInfo.mallCarts || []
  mallCarts.forEach(mall => {
    mall.shops?.forEach(shop => {
      shop.contentData?.forEach(content => {
        carts = carts.concat(content.productLineInfoList || [])
      })
    })
  })
  return {
    code: '0',
    info: {
      carts
    }
  }
}

export const useMiniCart = () => {
  const getAddressInfo = () => {
    const addressCookie = getLocalStorage('addressCookie')
    let addressInfo = {}
    if (addressCookie) {
      try {
        addressInfo = typeof addressCookie === 'string' ? JSON.parse(addressCookie) : addressCookie
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('获取地址缓存失败', error)
      }
    }
    return addressInfo
  }
  const getUserLocalSizeCountry = () => {
    if (typeof window !== 'undefined') {
      return window.localStorage.getItem('last_select_country') || ''
    }
    return ''
  }
  // 修改勾选状态
  const onBeforeClickModifyCheckbox = (checked, type) => {
    web_modify_check_click(checked, type)
  }
  // 修改勾选状态结果
  const onAfterClickModifyCheckbox = (checked, type, code) => {
    web_modify_check_response(checked, type, code)
  }
  // 购物车更新
  const onMiniCartUpdate = ({ config, cartRes } = {}) => {
    prefetchCart()
    if (!window?.cart_module) {
      window.cart_module = {}
    }
    if (!window.cart_module.cartVue) {
      window.cart_module.cartVue = {}
    }
    !window.cart_module.cartVue.res && (window.cart_module.cartVue.res = getCartInfo(cartRes))
    !window.cart_module.cartVue.carts && (window.cart_module.cartVue.carts = getCartInfo(cartRes).info.carts)
    if (!config?.disableCartTagTip) {
      cartTagTip.update({
        noTips: true,
        needUpdateCartNum: true,
        cartInfo: cartRes,
      })
    }
  }
  const onMiniCartMounted = () => {
    requestIdleCallback(() => {
      prefetchResources()
    })
  }

  const onClickViewCart = () => {
    web_cart_entry_click(4)
  }

  const onClickCartBag = () => {
    web_cart_entry_click(3)
  }

  return {
    getAddressInfo,
    getUserLocalSizeCountry,
    onBeforeClickModifyCheckbox,
    onAfterClickModifyCheckbox,
    onMiniCartUpdate,
    onMiniCartMounted,
    onClickViewCart,
    onClickCartBag
  }
}
