/**
 * wiki.pageId=1163602008
 * 搜索引导词模块相关试验参数 兼容
 */
const SEARCH_MODULE_POS_KEYS = ['SearchSuggest', 'SearchDefault', 'SearchHot2']


function isSearchModulePosKey(sceneStr, posKey) {
  return SEARCH_MODULE_POS_KEYS.includes(sceneStr)
    || SEARCH_MODULE_POS_KEYS.includes(posKey)
}

export function formatSearchModuleAbtParam(posKeyObj) {
  const { sceneStr = '', p = '', param = '', posKey = '' } = posKeyObj || {}
  if (!isSearchModulePosKey(sceneStr, posKey)) return ''
  const matchStr = 'type_id='
  if (p && param && p?.startsWith?.(matchStr)) {
    return p.replace(matchStr, '')
  }
  return ''
}
