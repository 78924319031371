import { ref, onMounted } from 'vue'

export function useCommonHeader({ commonHeaderRef }) {
  const showAllHeader = ref(true)
  const noFocus = ref(false)

  const showCommonHeader = (val) => {
    showAllHeader.value = val
    window.showAllHeader = val
  }

  const headerMouseDown = () => {
    noFocus.value = true
  }
  const headerKeydown = () => {
    noFocus.value = false
  }
  

  onMounted(() => {
    window.showAllHeader = showAllHeader.value
    window.pageScrollHandle = commonHeaderRef?.value?.pageScrollHandle
  })
  return { showAllHeader, noFocus, showCommonHeader, headerMouseDown, headerKeydown }
}
