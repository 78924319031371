import { prefetchResource } from 'public/src/services/prefetchResource'
import UserInfoManager from 'public/src/services/UserInfoManager'

/**
 * @description: 判断是否是IE浏览器
 */
export function  isIE () {
  if(!!window.ActiveXObject || 'ActiveXObject' in window || window.navigator.userAgent.indexOf('Edg') > -1) {
    return true
  }
  return false
}

export function prefetchResources() {
  prefetchResource.prefetchJs({
    prefetchList: [
      {
        chunkName: 'cart_new',
        relType: 'prefetch'
      },
      {
        chunkName: 'cart_top_part',
        relType: 'prefetch'
      },
      {
        chunkName: 'checkout',
        relType: 'prefetch'
      }
    ]
  })
}

export function onvisibilitychange() {
  const active = document.visibilityState !== 'hidden'
  if (active) {
    window.miniCartCheckLogin = UserInfoManager.get({ key: 'UserInfo', options: { noCache: true }, actionType: 'cart/checkLogin' })
  } else {
    window.miniCartCheckLogin = null
  }
}
