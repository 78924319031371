import schttp from 'public/src/services/schttp'

// 推荐数据是否返回有qs的数据，购物袋是多mall（不存在qs数据）或者 没有qs数据
const getQSParam = (carts, mallCode) => {
  let res = -1
  try {
    if (Array.isArray(carts) && carts?.length > 0) {
      const isMultipleMall = mallCode.indexOf(',') > -1
      const qsProductLength = carts?.filter(product => product.real_quick_ship == 1).length
      if (!qsProductLength || isMultipleMall) {
        res = 0
      } else if (qsProductLength && qsProductLength === carts.length) {
        res = 1 
      }
    }
  } catch (e) {
    console.error('getQSParam Error', e)
    res = -1
  } finally {
    return res 
  }
}
  
// 购物袋所有mallCode
const getAllMallCode = (carts) => {
  let res = ''
  try {
    const code = new Set()
    if (Array.isArray(carts) && carts?.length > 0) {
      carts.forEach(product => code.add(product.mall_code))
    }
    res = [...code].join(',')
  } catch (e) {
    console.error('getAllMallCode Error', e)
    res = ''
  } finally {
    return res 
  }
}
  
// 各种判断格式化函数
const formatFn = ({ abt, carts, exposedGoodsId, countryId }) => {
  const cartsData = Array.isArray(carts) && carts?.length > 0 ? carts.filter(item => item?.is_checked === 1) : []
  const { AddToOrderGoods, GoodsWeb, PickupRec, buyNewCard, detailgoodsCard, listquickship, listquickshipKey, listquickshipLanguage } = abt
  const buyNewCardAbt = buyNewCard?.param?.buyNewCardstatus || 0
  const isAddToOrderGoods = Number(AddToOrderGoods?.param?.isAddToOrderGoods || 1)
  const isOneGoodWeb = GoodsWeb?.param?.isOneGoodWeb // pc单独新增是否为多商品
  const abtControl = [1, 2].includes(isAddToOrderGoods)
    ? isOneGoodWeb == 1 ? 1 : 2
    : 0
  const newProductCard = abtControl == 1 
    ? true 
    : buyNewCardAbt == 1 
      ? abt?.pclistnewCard?.param?.pclistnewCard || '' 
      : false  
  const detailgoodsCardAbt = detailgoodsCard?.param?.detailGoods || ''
  const mallCode = getAllMallCode(cartsData)
  const quickship = [ 108 ].includes(+countryId) ? 0 : getQSParam(carts, mallCode)
  const listquickshipLanguageByCart = listquickshipLanguage?.p?.listquickshipLanguage === 'cartLanguage'
  const result = {
    type: abtControl,
    jsonRuleId: JSON.stringify(PickupRec?.param),
    newProductCard,
    detailgoodsCardAbt,
    listquickshipLanguageByCart,
    contextParams: {
      goods_id: cartsData?.map(cart => cart.product?.goods_id).join(','),
      mall_code: mallCode,
      quickship,
      exposed_goods_id: exposedGoodsId || ''
    },
    abtInfo: {
      listQuickShip: listquickship?.p?.listquickship,
      listquickKey: listquickshipKey?.p?.listquickKey
    }
  }
  return result
}

// 获取随手购abt
const getAbtInfo = async () => {
  const res = await schttp({
    method: 'POST',
    url: '/api/checkout/togetherAbt/get'
  })
  if(res.code == 0){
    return res.info
  } else {
    return {}
  }
}

const getAbtResult = async (abtResult) => {
  let res = {}
  try {
    if(!abtResult || typeof abtResult != 'object' || (typeof abtResult == 'object' && !Object.keys(abtResult).length)){
      res = await getAbtInfo()
    } else {
      res = abtResult
    }
  } catch (e) {
    res = {}
  } finally {
    return res
  }
}

// 组装随手购需要用到的 carts, abt
const cartsAbtBuild = ({ carts, abt } = { carts: [], abt: {} }) => {
  const abtResult = {}
  const needAbtPoskey = [ 'AddToOrderGoods', 'AddToOrderGoodsLocation', 'AddToOrderGoodsShow', 'PickupRec', 'pclistnewCard', 'detailgoodsCard', 'buyNewCard', 'EstimatedPrice', 'FeaturePriorWeb', 'FastAddWeb', 'GoodsWeb', 'listquickship', 'listquickshipKey', 'listquickshipLanguage', 'listwithS3']
  Object.entries(abt).forEach(([key, value]) => {
    if(needAbtPoskey.includes(key)){
      abtResult[key] = value
    }
  })
  const params = { 
    carts: carts.map(item=> {
      const { real_quick_ship, mall_code, product } = item
      return { real_quick_ship, mall_code, product: { goods_id: product.goods_id } }
    }), 
    abt
  }
  return params
}


/**
 * carts 购物袋 必传
 * exposedGoodsId 首次可不传，默认空
 * abt 可不传，自动获取abt接口
 */
const useTogether = (() => {
    let abtResult
    getAbtResult().then(abtParams => { abtResult = abtParams })
    return async ({ carts, exposedGoodsId, abt } = { carts: [], exposedGoodsId: '', abt }) => {
      console.time('useTogether time')
      let res = {}
      abtResult = abt ?? abtResult
      try {
        if (typeof window !== 'undefined') {
          let abtParams = await getAbtResult(abtResult)
          res = formatFn({ abt: abtParams, carts, exposedGoodsId })
        }
      } catch (e) {
        res = {}
        console.error('useTogether Error', e) 
      } finally {
        console.timeEnd('useTogether time')
        return res || {}
      }
    }
})()
export {
  formatFn,
  useTogether,
  cartsAbtBuild
}
