import { computed } from 'vue'
import messageStoreStore from 'public/src/pages/common/messageStore/index.js'

export const useGlobalMapState = (stateKeys) => {
  const store = messageStoreStore
  return Object.fromEntries(
    stateKeys.map(key => [
      key,
      computed(() => store.state[key])
    ])
  )
}

export const useGlobalMapMutations = (mutationKeys) => {
  const store = messageStoreStore
  return Object.fromEntries(
    mutationKeys.map(mutation => [
      mutation,
      value => store.commit(`${mutation}`, value)
    ])
  )
}

