import { ref, onMounted } from 'vue'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import { useGlobalMapState, useGlobalMapMutations } from './store.js'

export function usePersonalCenter() {
  const accountManager = ref('')

  const { couponOrPointsData, unReadNum, isMsgActivityAndPromoOnly } = useGlobalMapState(['couponOrPointsData', 'unReadNum', 'isMsgActivityAndPromoOnly'])
  const { changeCouponOrPointsData, changeActivityAndPromoOnly, changeUnreadNum } = useGlobalMapMutations(['changeCouponOrPointsData', 'changeActivityAndPromoOnly', 'changeUnreadNum'])

  const handleChangeCouponOrPointsData = (data) => {
    changeCouponOrPointsData(data)
  }

  const handleActivityAndPromoOnly = (data) => {
    changeActivityAndPromoOnly(data)
  }

  const handleUnreadNum = (data) => {
    changeUnreadNum(data)
  }

  const handleInitCouponOrPointsNoticeDone = () => {
    window.appEventCenter?.emit('initCouponOrPointsNoticeDone')
  }

  onMounted(async() => {
    const { AccountManager } = await getUserAbtData()
    accountManager.value = AccountManager?.param?.AccountManager || 'off'
  })

  return { 
    accountManager,
    couponOrPointsData,
    unReadNum,
    isMsgActivityAndPromoOnly,
    handleChangeCouponOrPointsData,
    handleActivityAndPromoOnly,
    handleUnreadNum,
    handleInitCouponOrPointsNoticeDone,
  }
}
